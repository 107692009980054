<template>
<div class="bg-white p-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <h1 class="h4 m-0">Shade Matching</h1>
    </div>
    <p>To book your appointments please use the link below</p>
    <a href="https://calendly.com/joao-108/shade-matching-swift" target="_blank" class="btn btn-primary">Book appointment</a>
</div>
</template>
<script>

export default {

}
</script>